<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight plr13 pb10">
      <div class="recordList pt10" v-if="historyExamList.length > 0">
        <div class="item"
             v-for="exam in historyExamList"
             :key="exam.examCode">
          <div class="flex align-center justify-between">
            <span class="flex1 text-cut">{{ exam.title }}</span>
            <span class="grade ml10">{{
                null == exam.grade || undefined == exam.grade ? $t('scoreTip') : exam.grade+$t('my.points')
              }}</span>
          </div>
          <div class="text999 f12">
            {{$t('my.testTime')}}：{{ exam.createDt | dataFormat }}
          </div>
        </div>
      </div>
           <!-- 结果为空 -->
        <div v-if="historyExamList.length == 0" class="kong positiona">
          <img src="../../assets/images/kong.png" alt="" width="150" />
          <div class="f15 textc mt15 text999">{{ $t("my.noTestLogging") }}~</div>
        </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/commonHead.vue";
import Footer from "../../components/footer.vue";
export default {
  components: { Header, Footer },
  data () {
    return {
      historyExamList: [],
      currPage: 1,
      totalPage: 1,
      pageSize: 10,
    };
  },
  methods: {
    loadHistoryExamList () {
      this.showLoading = true;
      var sendData = {};
      sendData.currPage = this.currPage;
      sendData.pageSize = this.pageSize;
      sendData.memberCode = localStorage.getItem("memberCode");
      var that = this;
      this.$http
        .post("memberExam/queryHistoryExamList", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            for (var i = 0; i < res.data.data.data.length; i++) {
              that.historyExamList.push(res.data.data.data[i]);
            }
            that.currPage = res.data.data.currPage;
            that.totalPage = res.data.data.pageCount;
            that.showLoading = false;
          }
        });
    },
  },
  created () {
    this.loadHistoryExamList();
  },
};
</script>

<style scoped>
.item {
  border-bottom: 1px solid #f5f5f5;
  padding: 10px 0;
}
.item:last-of-type {
  border: none;
}
.grade {
  width: 60px;
}
</style>